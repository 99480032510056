import { Component, Vue, Prop } from 'vue-property-decorator';
import { UIComponent } from 'ui-controls-core/ui-component';
import { ModalPlugin } from 'bootstrap-vue';
import WithRender from './auth-dialog.html';

import './auth-dialog.scss';

Vue.use(ModalPlugin);

@WithRender
@Component({})
export class AuthDialog extends UIComponent {
	constructor() {
		super();
	}

	public noCloseOnBackdrop = false;

	public created() {
		$(document).on('mouseup', () => {
			setTimeout(() => {
				this.noCloseOnBackdrop = false;
			}, 500);
		});
		$(document).on('dragend', () => {
			setTimeout(() => {
				this.noCloseOnBackdrop = false;
			}, 500);
		});
	}

	public onMouseDown() {
		Vue.set(this, 'noCloseOnBackdrop', true);
	}

	public onHide() {
		if (this.$route.params.closeUrl) {
			this.$router.push(this.$route.params.closeUrl);
		}
	}
}
